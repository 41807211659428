import "regenerator-runtime/runtime";
import { createApp } from "vue";
import { Vue3Mq } from "vue3-mq";
import Oruga from "@oruga-ui/oruga-next";
import XBaseUI from "@xtremax/xbase-ui";
import XBaseMasba from "@xtremax/xbase-masba";
import { themeConfig, customIconConfig } from "@xtremax/masba-xbase-ui-theme";

import "@xtremax/xbase-ui/dist/xbase-ui.css";
import "@xtremax/xbase-masba/dist/xbase-masba.css";
import "@oruga-ui/theme-bulma/dist/bulma.css";
import "@xtremax/masba-xbase-ui-theme/dist/theme-xbase-masba.css";

import "font-awesome/css/font-awesome.css";
import "@mdi/font/css/materialdesignicons.css";
import "@xtremax/masba-xbase-ui-theme/dist/fonts/_fonts.css";

import "./styles/main.scss";

import router from "./router";
import App from "./App.vue";
import store from "./store";

const app = createApp(App);

app.use(Oruga, { ...themeConfig, ...customIconConfig });
app.use(XBaseUI);
app.use(XBaseMasba);
app.use(Vue3Mq, {
  breakpoints: {
    smallMobile: 0,
    mobile: 320,
    tablet: 768,
    desktop: 1025,
    widescreen: 1920,
  },
});
app.use(store);
app.use(router);
app.mount("#app");
