<template>
  <RouterView />
</template>

<script setup>
import { watch } from "vue";
import { useRoute } from "vue-router";
import { useGlobalVariables } from "@/composables/useGlobalVariables";

const route = useRoute();
const { isDevEnvironment, checkAuthentication } = useGlobalVariables();
const isAuthenticated = checkAuthentication();

watch(
  () => route,
  () => {
    if (!isDevEnvironment && isAuthenticated) {
      const site_url = window.configs.toppanUrl;
      const profile = window.configs.toppanProfile;
      // eslint-disable-next-line
      pollXhrRequest(site_url, profile);
    }
  },
  { deep: true, immediate: true },
);
</script>
